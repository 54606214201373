import Header from "../../components/header/Header";
import {
  Document,
  PDFViewer,
  StyleSheet,
  View,
  Text,
  Page,
} from "@react-pdf/renderer";

import { Link } from "react-router-dom";
import { useState, useEffect, useContext, useCallback } from "react";
import { context } from "../../contexts";
import FormatCurrency from "./../../helpers/FormatCurrency";
import { format } from "date-fns";
import FormatDate from "../../helpers/FormatDate";
export default function ReportSintetic({ close }) {
  const date = new Date();
  //enumera as paginas
  const [currentPage, setCurrentPage] = useState(1);

  const [user, setUser] = useState("");
  const { notesGlobal } = useContext(context);

  useEffect(() => {
    const res = localStorage.getItem("@user");
    const data = JSON.parse(res);
    if (data) {
      setUser(data.usuario);
    }
  }, []);

 

  const handlePageChange = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);
  //documento pf para test
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
    },
    header: {
      margin: 10,
      padding: 10,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    textHeader: {
      fontSize: 8,
      fontWeight: "bold",
    },
    title: {
      textAlign: "center",
      fontSize: 14,
    },

    // outros estilos
    table: {
      marginTop: 30,
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderBottomWidth: 1, // Adicione a borda inferior aqui
      marginLeft: 10,
      marginRight: 20,
      borderColor: "#000", // Certifique-se de que a cor da borda está definida,
      borderLeft: 0,
      borderRight: 0,
    },
    tableRow: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottomWidth: 1, // Adicione a borda inferior para cada linha
      borderBottomStyle: "solid",
      borderColor: "#000", // Cor da borda
    },
    tableRowBody: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    tableColHeader: {
      width: "25%",
      borderStyle: "solid",
      alignItems: "center",
      justifyContent: "center",
    },
    tableCol: {
      width: "25%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderBottomWidth: 0, // Remova a borda inferior das colunas dos dados
      alignItems: "flex-start",
      justifyContent: "center",
      paddingLeft: 5,
    },
    // outros estilos

    text: {
      fontSize: 8,
      fontWeight: "normal",
      textAlign: "center",
      marginTop: 10,
    },
    pageNumber: {
      fontSize: 8,
      position: "absolute",
      bottom: 10, // Posiciona o número da página na parte inferior
      left: 10, // Adiciona um espaçamento à esquerda
    },
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
      border: 1,
    },
  });
  // Create styles
  // Create Document Component

  const getTotal = () => {
    return notesGlobal.reduce((acc, value) => {
      return acc + (Number(value.TOTAL) || 0);
    }, 0);
  };

  function FormatHours(value) {
    return format(value, "HH:mm:ss");
  }

  

  return (
    <div className="">
      <div className="mx-8 my-8 max-md:mt-14">
        <button
          className=" bg-orange-500 text-white px-4 rounded-md text-sm
                    hover:bg-orange-300 max-w-[15rem] min-h-7
                    "
          onClick={close}
        >
          Fechar
        </button>

        <div className="my-4 w-full overflow-auto">
          <PDFViewer className="w-[80vw] h-[80vh]" >
            <Document>
              <Page size="A4" style={styles.body} wrap>
                <View>
                  <Text style={[styles.text, { textAlign: "center" }]}>
                    RELAÇÃO DE NOTAS EMITIDAS
                  </Text>
                  <View style={styles.header}>
                    <View style={{ flexDirection: "column" }}>
                      <Text style={styles.text}>Emitente: {user}</Text>
                      <Text style={styles.text}>
                        Cliente: {notesGlobal[0].NOME}
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.textHeader}>eRepositórioXML</Text>
                   <Text style={styles.textHeader}>Emissão: {FormatDate(date)}  </Text>    
                    </View>
                  </View>
                </View>

                <View style={styles.section}>
                  <View style={styles.table}>
                    <View style={[styles.tableRow, { marginBottom: 10 }]} fixed>
                      <View style={[styles.tableColHeader, { width: "15%" }]}>
                        <Text style={styles.text}>NÚMERO</Text>
                      </View>
                      <View style={[styles.tableColHeader, { width: "10%" }]}>
                        <Text style={styles.text}>MODELO</Text>
                      </View>
                      <View style={[styles.tableColHeader, { width: "10%" }]}>
                        <Text style={styles.text}>SÉRIE</Text>
                      </View>
                      <View style={[styles.tableColHeader, { width: "40%" }]}>
                        <Text style={styles.text}>CHAVE DE ACESSO</Text>
                      </View>
                      <View style={[styles.tableColHeader, { width: "25%" }]}>
                        <Text style={styles.text}>CPF/CNPJ</Text>
                      </View>
                      <View style={[styles.tableColHeader, { width: "10%" }]}>
                        <Text style={styles.text}>TOTAL </Text>
                      </View>
                    </View>

                    {notesGlobal.map((item) => (
                      <View>
                        <View style={styles.tableRowBody}>
                          <Text style={[styles.text, { width: "15%" }]}>
                            {item.SEQ_NOTA}
                          </Text>
                          <Text style={[styles.text, { width: "10%" }]}>
                            {item.MODELO}
                          </Text>
                          <Text style={[styles.text, { width: "10%" }]}>
                            {item.SERIE}
                          </Text>
                          <Text style={[styles.text, { width: "45%" }]}>
                            {item.CHAVEACESSO}
                          </Text>
                          <Text style={[styles.text, { width: "25%" }]}>
                            {item.CNPJ}
                          </Text>
                          <Text style={[styles.text, { width: '15%' }]}>{item.TOTAL ? FormatCurrency(item.TOTAL) : 'R$ 0,00'}</Text>
                        </View>
                      </View>
                    ))}
                  </View>
                </View>

                <View
                  style={[
                    styles.text,
                    {
                      marginTop: 20,
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-between",
                    },
                  ]}
                >
                  <Text>QTDE DE VENDAS: {notesGlobal.length}</Text>
                  <Text style={styles.text}>
                    TOTAL GERAL: {FormatCurrency(getTotal())}
                  </Text>
               
               
                </View>

               
               
              </Page>
            </Document>
          </PDFViewer>
        </div>
      </div>
    </div>
  );
}

import Header from './../../components/header/Header';
import { useContext, useState, useEffect } from 'react';
import { context } from '../../contexts';
import SearchBar from './../../components/Searchbar';
import TableClients from '../../components/TableClients';
import { Button, Card, Modal } from "@mui/material";
import FormUser from './formUsers';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { toast } from 'react-toastify';
import api from '../../services/api';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MonitoringDownloadXml from '../../components/MonitoringDownloadXml';
export default function Users() {
    const [search, setSearch] = useState('')
    //controla o modal
    const [open, setOpen] = useState(false)

    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [adress, setAdress] = useState('')
    const [phone, setPhone] = useState('')
    const [city, setCity] = useState('')
    const [uf, setUf] = useState('')
    const [atv, setAtv] = useState(true)
    const [intv, setIntv] = useState(false)
    const [cep, setCep] = useState('')
    const [superv, setSuperv] = useState(false)
    const [counter, setCounter] = useState(true)
    const [tipo, setTipo] = useState('C')
    const [ativo, setAtivo] = useState('S')
    //armazena o usuario selecionado
    const [selected, setSelected] = useState('')
    const usuarios = [
        {
            codigo: 1,
            login: "usuario1",
            nome: "João Silva",
            telefone: "(xx) xxxx-xxxx",
            cidade: "São Paulo",
            cep: "00000-000",
            endereco: "Rua das Flores, 123",
            uf: "SP",
            ativo: true,
            supervisor: false,
            contador: true
        },
        {
            codigo: 2,
            login: "usuario2",
            nome: "Maria Souza",
            telefone: "(xx) xxxx-xxxx",
            cidade: "Rio de Janeiro",
            cep: "11111-111",
            endereco: "Av. das Palmeiras, 456",
            uf: "RJ",
            ativo: true,
            supervisor: true,
            contador: false
        },
        {
            codigo: 3,
            login: "usuario3",
            nome: "Carlos Oliveira",
            telefone: "(xx) xxxx-xxxx",
            cidade: "Belo Horizonte",
            cep: "22222-222",
            endereco: "Travessa das Pedras, 789",
            uf: "MG",
            ativo: false,
            supervisor: true,
            contador: false
        },
        {
            codigo: 4,
            login: "usuario4",
            nome: "Ana Santos",
            telefone: "(xx) xxxx-xxxx",
            cidade: "Salvador",
            cep: "33333-333",
            endereco: "Rua do Sol, 101",
            uf: "BA",
            ativo: true,
            supervisor: false,
            contador: true
        },
        {
            codigo: 5,
            login: "usuario5",
            nome: "Pedro Lima",
            telefone: "(xx) xxxx-xxxx",
            cidade: "Brasília",
            cep: "44444-444",
            endereco: "Quadra 301, Bloco A",
            uf: "DF",
            ativo: true,
            supervisor: true,
            contador: true
        }
    ];
    const [listUsers, setListUsers] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const [confirmDelet, setConfirmDelete] = useState(false)

    async function getUsers() {
        setLoading(true)
        await axios.get(`${api}usuario`)
            .then((res) => {
                setFilteredList(res.data)
                setListUsers(res.data)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                toast.error('Erro ao buscar usuários')
            })

    }

    useEffect(() => {
        getUsers()
    }, [])

    useEffect(() => {
        getUsers()
    }, [reload])
    //verifica se o termo pesquisado e um numero
    function isNumber(value) {
        return /^\d+$/.test(value)

    }
    async function SearchUser() {
        if (search !== '') {
            if (isNumber(search)) {
                SearchByCode()
            } else {
                SearchByName()
            }

        } else {
            toast.warn('É necessário um valor de busca')
        }

    }


    function SearchByName() {
        setLoading(true)
        const filter = listUsers.filter((item) =>
            item.NOME.toUpperCase().includes(search.toUpperCase()) ||
            item.CODUSUARIO.toUpperCase().includes(search.toUpperCase())
        );

        if (filter.length > 0) {
            setFilteredList(filter)
            setLoading(false)
        } else {
            setFilteredList([])
            toast.warn('Nenhum usuário encontrado!')
            setLoading(false)
        }
    }
    function SearchByCode() {

        setLoading(true)
        const filter = listUsers.filter((item) => item.CODIGO.toString() === search)
        if (filter.length > 0) {
            setFilteredList(filter)
            setLoading(false)
        } else {
            setFilteredList([])
            toast.warn('Nenhum usuário encontrado!')
            setLoading(false)
        }
    }


    // se o searcg for vazio ele seta a lista com  os dados originais
    useEffect(() => {
        if (search === '') {
            setFilteredList(listUsers)
        }

    }, [search])




    async function DeleteUser() {
        setLoading(true)
        setConfirmDelete(false)
        await axios.delete(`${api}usuario/${selected.CODIGO}`)
            .then(() => {
                toast.success('Usuário deletado com sucesso')
                setLoading(false)
                getUsers()

            })
            .catch((error) => {
                setLoading(false)
                toast.error('Erro ao deletar usuário')
            })


    }

    return (
        <div className="flex h-[100vh]">
            <Header />
            <div className='mx-8 my-8 max-md:mt-14'>
                <p className="font-semibold my-2">Usuários</p>

                <div className="flex gap-1 max-md:grid grid-cols-1">
                    <SearchBar
                        search={search}
                        setSearch={setSearch}
                        placeholder='Buscar usuários por nome ou código'
                        onClick={SearchUser}
                    />


                    {
                        /**botao para adicionar novo cliente */
                    }
                    <button className='mx-12 bg-orange-500 text-white px-4 rounded-md text-sm
                    hover:bg-orange-300 max-w-[12rem]
                    '
                        onClick={() => {
                            setSelected('')
                            setOpen(true)

                        }}
                    >
                        Adicionar usuário
                    </button>




                </div>
                {
                    loading ?
                        <div className='absolute left-[50%] top-[50%]'>
                            <CircularProgress color='warning' />
                        </div> :
                        <TableClients data={filteredList} setSelected={setSelected} setOpen={setOpen} setConfirmDelete={setConfirmDelete} />}

            </div>
            <Modal
                className="flex justify-center items-center"
                open={open}

            >
                <FormUser setOpen={setOpen}
                    email={email} setEmail={setEmail}
                    name={name} setName={setName}
                    adress={adress} setAdress={setAdress}
                    phone={phone} setPhone={setPhone}
                    city={city} setCity={setCity}
                    cep={cep} setCep={setCep}
                    uf={uf} setUf={setUf}
                    atv={atv} setAtv={setAtv}
                    intv={intv} setIntv={setIntv}
                    superv={superv} setSuperv={setSuperv}
                    setCounter={setCounter} counter={counter}
                    selected={selected} setSelected={setSelected}
                    tipo={tipo}
                    setTipo={setTipo}
                    setAtivo={setAtivo}
                    ativo={ativo}
                    reload={reload}
                    setReload={setReload}
                />
            </Modal>
            <Dialog
                open={confirmDelet}

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Tem certeza que deseja deletar este usuário?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Você está prestes a deletar o usuário {selected.NOME}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDelete(false)}>CANCELAR</Button>
                    <Button onClick={DeleteUser} >
                        CONFIRMAR
                    </Button>
                </DialogActions>
            </Dialog>

            <MonitoringDownloadXml/>
        </div>
    )
}
import { Alert, Snackbar } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ContextAuth, { context } from "../../contexts";
import { Info } from "lucide-react";

export default function MonitoringDownloadXml() {
  const {
    loadXmls,
    duration,
    setDuration,
    formatTime,
    textLoadXmls,
    cancelDownload,
  } = useContext(context);

  useEffect(() => {
    if (loadXmls === false) {
      setDuration(0);
    }
  }, [loadXmls]);
  // Função para formatar o tempo em HH:MM:SS

  const action = formatTime(duration);
  return (
    <Snackbar
      open={loadXmls}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        severity="info"
        icon={<Info />}
        variant="filled"
        sx={{ width: "100%" }}
      >
        <p>
          {textLoadXmls}... {action}
        </p>
        <p className="text-sm">Não recarregue a página</p>

        <button
          className="bg-white text-black px-2 rounded-sm"
          onClick={cancelDownload}
        >
          Cancelar
        </button>
      </Alert>
    </Snackbar>
  );
}

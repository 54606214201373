import { DataGrid } from "@mui/x-data-grid";
import { Check, FileCode, Pencil, Printer, TrashIcon, X } from "lucide-react";
import { useState, useContext } from "react";
import { context } from "../contexts";
import { useNavigate } from "react-router-dom";

import FormatCurrency from "../helpers/FormatCurrency";
import axios from "axios";
import api from './../services/api';
import { toast } from 'react-toastify';
import verificarTipoNotaFiscal from "../helpers/VerifyNote";
import jsPDF from 'jspdf';
import { format, parseISO, addDays, addHours } from 'date-fns'
export default function TableNotes({ data, setOpenNote, setOpenNoteCanceled, setXmlNotaCancelada }) {


    const { selected, setSelected, keyNote, setKeyNote, userData, tiponfce, setTipoNfce, xmlEvento, setXmlEvento } = useContext(context)
    const [xml, setXml] = useState('')
    const [loading, setLoading] = useState(false)


    const navigate = useNavigate()

    // quando clica no icone do xml, faz a chamada na api enviando a chave de acesso nda nota
    ///para buscar o xml e fazer doiwnload
    async function getXml(key) {
        setLoading(true)
        console.log(`${api}notafiscal/xml?chave=${key}`)
        await axios.get(`${api}notafiscal/xml?chave=${key}`)
            .then((res) => {
                if (res.data.XML_EVENTO !== null) {
                    setXml(res.data.XML_EVENTO)

                    saveXMLFile(res.data.XML_EVENTO, key)
                } else {
                    setXml(res.data.XML)

                    saveXMLFile(res.data.XML, key)
                }


            }).catch((error) => {
                toast.error('Erro ao buscar xml')
                setLoading(false)
            })

    }

    // Função para salvar o arquivo XML
    const saveXMLFile = (xmlData, filename) => {
        const blob = new Blob([xmlData], { type: 'text/xml' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        setLoading(false)
    };




    //QUANDO CLICA NO ICONE DE IMPRESSAO ELE REALIA ESSA FUNCAO

    async function getXmlForPrint(key, status) {
        setLoading(true)
        await axios.get(`${api}notafiscal/xml?chave=${key}`)
            .then((res) => {
                setXml(res.data.XML)
                if (status === 'C') {

                    setXmlNotaCancelada(res.data.XML)
                    setXmlEvento(res.data.XML_EVENTO)
                    setOpenNoteCanceled(true)
                    setLoading(false)

                } else {
                    sendXml(res.data.XML)
                    setLoading(false)
                }



            }).catch((error) => {
                toast.error('Erro ao buscar xml print')
                setLoading(false)
            })

    }

    async function sendXml(xml) {
        const stringXml = xml.toString()

        await axios.post(`https://ws.meudanfe.com/api/v1/get/nfe/xmltodanfepdf/API`,
            stringXml, {
            headers: {
                'Content-Type': 'text/plain'
            }
        }


        )
            .then((res) => {

                setLoading(false)
                const base64Data = res.data; // Assumindo que res.data contém o PDF em base64
                const byteCharacters = atob(base64Data);
                const byteNumbers = new Array(byteCharacters.length);

                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);

                window.open(url, '_blank');


            })
            .catch((error) => {
                console.log(error, 'erro ao enviar xml')
                toast.error('erro ao enviar xml')
                setLoading(false)
            })
    }



    //colunas notas
    const columns = [
        { field: 'MODELO', headerName: 'Mod', width: 40 },
        { field: 'SERIE', headerName: 'Série', width: 40 },
        { field: 'SEQ_NOTA', headerName: 'Número', width: 70 },
        { field: 'CHAVEACESSO', headerName: 'Chave de acesso', width: 200 },
        { field: 'CNPJ', headerName: 'CNPJ', width: 150 },
        { field: 'NOME', headerName: 'Cliente', width: 150 },
        {
            field: 'STATUS', headerName: 'STATUS', width: 80,
            renderCell: (params) => (
                <div className="mt-4">
                    {params.row.STATUS === 'O' ?
                        <Check className="text-green-500" size={20} /> : <X className="text-red-500" size={20} />
                    }
                </div>
            )

        },
        { field: 'DATACARREGOU', headerName: 'Data', width: 100 },
        { field: 'HORACARREGOU', headerName: 'Hora', width: 80 },
        { field: 'TOTAL', headerName: 'Total R$', width: 100 },
        {
            field: 'DANFe',
            headerName: 'DANFe',
            width: 50,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <div className={loading ? 'flex text-center mt-4 cursor-progress' : 'cursor-pointer flex text-center mt-4'}
                    onClick={() => {
                        const tipo = verificarTipoNotaFiscal(params.row.CHAVEACESSO)
                        if (tipo === 'NF-e') {
                            getXmlForPrint(params.row.CHAVEACESSO, params.row.STATUS)
                        } else {
                            const getXml = async () => {
                                setLoading(true);
                                setKeyNote(params.row.CHAVEACESSO)
                                setTipoNfce(params.row.STATUS)
                                try {
                                    const res = await axios.get(`${api}notafiscal/xml?chave=${params.row.CHAVEACESSO}`);
                                    setSelected(res.data.XML);

                                    setOpenNote(true)
                                } catch (error) {
                                    toast.error('Erro ao buscar xml');
                                } finally {
                                    setLoading(false);
                                }
                            };
                            getXml();
                        }


                    }}
                >
                    <Printer size={20}
                        className='text-gray-600 '
                    />
                </div>
            ),
        },
        {
            field: 'XML',
            headerName: 'XML',
            width: 50,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <div className={loading ? 'cursor-progress flex text-center mt-4' : "cursor-pointer flex text-center mt-4"}
                    onClick={() => {

                        getXml(params.row.CHAVEACESSO)



                    }}
                >
                    <FileCode
                        size={20}
                        className='text-gray-600 '
                    />
                </div>
            ),
        }


    ];

    function FormatDate(value) {
        const dateParse = parseISO(value);
        const dateFormat = addDays(dateParse, 1);
        return format(dateFormat, 'dd/MM/yyyy')
    }

    function FormatHours(value) {
        const dateParse = parseISO(value);
        const dateFormat = addHours(dateParse, 3);
        return format(dateFormat, 'HH:mm:ss')
    }

    const rows = data.map((item) => ({
        ...item,
        id: item.CODIGO,
        DATACARREGOU: FormatDate(item?.DATACARREGOU),
        HORACARREGOU: FormatHours(item?.HORACARREGOU),
        TOTAL: FormatCurrency(item?.TOTAL)
    }))

    return (
        <div className=" h-[360px] mx-4">
            <DataGrid
                className=' w-[95%] max-lg:w-2/3 max-md:w-1/2 max-sm:w-1/3  text-sm'

                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 25]}
                localeText={{
                    footerPaginationRowsPerPage: 'Linhas por página:',
                    footerPaginationPage: 'Página:',
                    footerPaginationNextPage: 'Próxima página',
                    footerPaginationPreviousPage: 'Página anterior',
                    footerRowSelected: (count) => `${count.toLocaleString()} linha(s) selecionada(s)`,
                }}

            />
        </div>
    )

}
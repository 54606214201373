import Header from "../../components/header/Header";
import {
  Document,
  PDFViewer,
  StyleSheet,
  View,
  Text,
  Page,
} from "@react-pdf/renderer";
import FormatDate from "../../helpers/FormatDate";
import { Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { context } from "../../contexts";
import FormatCurrency from "./../../helpers/FormatCurrency";
import { format } from "date-fns";

export default function ReportAnalytcal({ close }) {
  //enumera as paginas
  const [currentPage, setCurrentPage] = useState(1);

  const {
    notesGlobal,
    consistencia,
    setConsistencia,
    dateConsistencia,
    setDateConsistencia,
  } = useContext(context);

  const FormatDate = (value) => {
    return format(value, "MM/yy");
  };
  const [user, setUser] = useState("");
  useEffect(() => {
    const res = localStorage.getItem("@user");
    const data = JSON.parse(res);
    if (data) {
      setUser(data.usuario);
    }
  }, []);
  //documento pf para test
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
    },
    header: {
      margin: 10,
      padding: 10,
      display: "flex",
      flexDirection: "column",
    },
    textHeader: {
      fontSize: 9,
      fontWeight: "bold",
    },
    title: {
      textAlign: "center",
      fontSize: 14,
    },
    table: {
      marginTop: 30,
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginLeft: 10,
      marginRight: 20,
    },
    tableRow: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    tableColHeader: {
      width: "25%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      alignItems: "center",
      justifyContent: "center",
    },
    tableCol: {
      width: "25%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,

      borderTopWidth: 0,
      alignItems: "flex-start", // Ajuste para alinhamento à esquerda
      justifyContent: "center",
      paddingLeft: 5, // Adiciona padding à esquerda para espaçamento
    },
    text: {
      fontSize: 10,
      fontWeight: "normal",
      textAlign: "center",
      marginTop: 10,
    },
    pageNumber: {
      fontSize: 8,
      position: "absolute",
      bottom: 10, // Posiciona o número da página na parte inferior
      left: 10, // Adiciona um espaçamento à esquerda
    },
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 16,
      textAlign: "center",
      marginBottom: 20,
    },
  });
  // Create styles
  // Create Document Component

  const info = notesGlobal[0];
  const { NOME, CNPJ } = info;

  
  return (
    <div className="flex ">
      <div className="mx-8 my-8 max-md:mt-14">
        <button
          className=" bg-orange-500 text-white px-4 rounded-md text-sm
                    hover:bg-orange-300 max-w-[15rem] min-h-7 mt-4
                    "
          onClick={close}
        >
          Fechar
        </button>

        <div className="my-4 w-full overflow-auto">
          <PDFViewer className="w-[80vw] h-[80vh]">
            <Document>
              <Page size="A4" style={styles.body} wrap>
                <View style={styles.header}>
                  <Text style={styles.title}>CONSISTÊNCIA DE NOTAS</Text>
                  <View style={{ gap: 2, alignItems: "flex-start" }}>
                    <Text style={styles.textHeader}>Emitente: {user}</Text>
                    <Text style={styles.textHeader}> {NOME}</Text>

                   <Text style={styles.textHeader}>MES/ANO {FormatDate(dateConsistencia)}</Text> 
                    <Text style={styles.textHeader}>CNPJ: {CNPJ}</Text>
                  </View>
                  <Text style={[styles.text, { marginTop: 10, fontSize: 12 }]}>
                    Relação de documentos fiscais faltantes
                  </Text>
                </View>
                <View style={styles.section}>
                  {consistencia.map((item) => (
                    <Text style={styles.text}>
                      Modelo: {item.modelo}, notas: {item.notas}
                    </Text>
                  ))}

                </View>
              </Page>
            </Document>
          </PDFViewer>
        </div>
      </div>
    </div>
  );
}

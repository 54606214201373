import { createContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import api from "./../services/api";
import { toast } from "react-toastify";
import FormatDate from "../helpers/FormatDate";
import { format } from "date-fns";
import JSZip from "jszip";
export const context = createContext();

export default function ContextAuth({ children }) {
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [signed, setSigned] = useState(false);
  const navigate = useNavigate();

  //armazena a nota selecionada
  const [selected, setSelected] = useState([]);
  function FormatDateMounth(value) {
    const formated = format(value, "MM");
    return formated;
  }
  function FormatDateYear(value) {
    const formated = format(value, "yy");
    return formated;
  }

  // realize o login e armazena os dados
  async function Login(user, password) {
    setLoading(true);
    const data = {
      login: user,
      password: password,
    };

    await axios
      .post(`${api}login`, data)
      .then((res) => {
        setLoading(false);
        if (res.data.retorno === true) {
          const data = res.data;
          const user = {
            usuario: data.usuario,
            tipo: data.tipo,
            mensagem: data.mensagem,
            retorno: data.retorno,
          };
          setUserData(res.data);
          // console.log(userData)
          localStorage.setItem("@user", JSON.stringify(user));
          setSigned(true);
          navigate("/home");
        } else {
          toast.error("Usuário ou senha incorretos");
        }
      })
      .catch((error) => {
        toast.error("Erro ao realizar login");
        setSigned(false);
        setLoading(false);
      });
  }

  useEffect(() => {
    async function VerifyUser() {
      const userData = localStorage.getItem("@user");
      console.log("verificando usuario", userData);
      if (userData) {
        const data = JSON.parse(userData);

        setUserData(data);
        setSigned(true); // Defina o estado signed como true se houver dados de usuário salvos
        navigate("/home");
      } else {
        setSigned(false);
      }
      setLoading(false); // Indique que a verificação foi concluída
    }
    VerifyUser();
  }, []);

  const [listCounters, setListCounters] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  async function getCounters() {
    setLoading(true);
    await axios
      .get(`${api}vinculo`)
      .then((res) => {
        setFilteredList(res.data);
        setListCounters(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Erro ao buscar contadores");
      });
  }

  //armazena as notas de forma global pra ser usada no relatorio
  const [notesGlobal, setNoteGlobal] = useState([]);
  const [consistencia, setConsistencia] = useState("");
  const [dateConsistencia, setDateConsistencia] = useState("");
  //armazena a chave de aceso
  const [keyNote, setKeyNote] = useState("");

  const [dashboardData, setDashboardData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [clients, setClients] = useState([]);
  async function getDashboard() {
    await axios
      .get(
        `${api}dashboard/${userData.usuario}/${
          userData.tipo
        }/${FormatDateMounth(date)}/${FormatDateYear(date)}`
      )
      .then((res) => {
        setDashboardData(res.data);
      })

      .catch((error) => {
        toast.error("Erro ao buscar dados do dashboard");
        console.error("Erro ao buscar dados do dashboard", error);
      });
  }
  async function getClients() {
    await axios
      .get(`${api}dashboard/${userData.usuario}`)
      .then((res) => {
        setClients(res.data);
      })
      .catch((error) => {
        toast.error("Erro ao buscar clientes vinculados");
      });
  }
  //quando forita a impressao de uma nota
  // do tipo nfce, sera armazenado aqui nessa const, se ela esta cancelada ou nao
  const [tiponfce, setTipoNfce] = useState("");

  // se a nota for cancelada ela gera um xml evento
  // o xml evento e armazenado nessa const.
  const [xmlEvento, setXmlEvento] = useState("");

  const [tipo, setTipo] = useState("");
  const [numberNote, setNumberNote] = useState("");
  const [serie, setSerie] = useState("");
  const [xmls, setXmls] = useState([]);
  const [loadXmls, setLoadXmls] = useState(false);
  const [textLoadXmls, setTextLoadXmls] = useState("Buscando XMLs para baixar");
  const [cnpj, setCnpj] = useState("");
  const [situation, setSituation] = useState("");
  const cancelTokenSource = useRef(null);
  const [keyAcess, setKeyAcess] = useState("");
  //armazena os xmls baixados
  const [downloadXml, setDownloadXml] = useState([]);
  //implementar função para baixar todos os xmls
  const DownloadsXml = async () => {
    setDuration(0);
    function FormatDateMount(value) {
      const formatt = format(value, "yy/MM");
      return formatt;
    }
    if (cnpj !== "") {
      setLoadXmls(true);
      setTextLoadXmls("Buscando XMLs para baixar");
      let page = 1;
      let hasNextPage = true;
      let xmlsArray = [];
      // Cria um novo cancel token para esta requisição
      cancelTokenSource.current = axios.CancelToken.source();
      while (hasNextPage) {
        try {
        
          const response = await axios.get(
            `${api}notafiscal/baixartudo/${cnpj}/${FormatDateMount(
              date
            )}?page=${page}`,
            {
              cancelToken: cancelTokenSource.current.token,
              timeout: 1000 * 60 * 120,
            }
          );

          const data = response.data;

          const xmls = data.data;
         
          // verifica se tem uma nova pagina ou nao, se tiver uma nova pagina , armazena os xmls na state antes de fazer a nova requisicao
          xmlsArray = [...xmlsArray, ...xmls];
          hasNextPage = data.hasNextPage;
          if (hasNextPage === true) {
            page++;
          } else {
            saveXMLFilesAsZip(xmlsArray);
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            setTextLoadXmls("Operação cancelada pelo usuário.");
          } else {
            console.log("Erro ao baixar XMLs", error);
            toast.error("Erro ao baixar XMLs", error.message);
          }
          setLoadXmls(false);
          break;
        }
      }
    } else {
      toast.warn("É necessário selecionar um CNPJ");
    }
  };

  const saveXMLFilesAsZip = (data) => {

    setTextLoadXmls("Gerando arquivo ZIP, por favor, aguarde");
    const zip = new JSZip();

    // Adiciona cada XML ao arquivo ZIP
    data.forEach((item, index) => {
      // Verifica se item.XML não é undefined ou null e é uma string
      if (item.XML && typeof item.XML === "string") {
        // se tiver um xmlevento sginifica que foi cancelada, entao baixa o evento , se nao baixa o xml comum
        if (item.XML_EVENTO !== null) {
          const fileName = `${item.CHAVEACESSO}.xml`;
          zip.file(fileName, item.XML_EVENTO);
        } else {
          const fileName = `${item.CHAVEACESSO}.xml`;
          zip.file(fileName, item.XML);
        }
      } else {
        console.warn(`Dados inválidos para o arquivo: ${index}`);
      }
    });

    // Gera o arquivo ZIP
    zip
      .generateAsync({ type: "blob" })
      .then((content) => {
        // Cria um URL para o arquivo ZIP
        const url = window.URL.createObjectURL(content);
        const a = document.createElement("a");
        a.href = url;
        a.download = "arquivos.zip"; // Nome do arquivo ZIP
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        setLoadXmls(false);
      })
      .catch((error) => {
        console.error("Erro ao gerar o arquivo ZIP:", error);

        setLoadXmls(false);
      });
  };
  const cancelDownload = () => {
    console.log("cancelando");
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Operação cancelada pelo usuário."); // Cancela a requisição
      setLoadXmls(false);
      setTextLoadXmls("Operação cancelada pelo usuário.");
    }
  };
  const [duration, setDuration] = useState(0);
  const [formattedTime, setFormattedTime] = useState("00:00:00"); // tempo formatado em HH:MM:SS
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(sec).padStart(2, "0")}`;
  };
  useEffect(() => {
    if (duration >= 0) {
      setFormattedTime(formatTime(duration)); // Atualiza o tempo formatado
    }
  }, [duration]); // Quando a duração mudar, formate novamente o tempo

  useEffect(() => {
    let interval;
    // Se "open" for true, começa a contar
    if (duration >= 0) {
      interval = setInterval(() => {
        setDuration((prevDuration) => prevDuration + 1); // Incrementa 1 segundo
      }, 1000); // Intervalo de 1 segundo
    } else {
      clearInterval(interval); // Caso contrário, limpa o intervalo
    }

    return () => clearInterval(interval); // Limpa o intervalo quando o componente desmontar
  }, [duration]);
  return (
    <context.Provider
      value={{
        open,
        setOpen,
        selected,
        setSelected,
        Login,
        loading,
        userData,
        getCounters,
        setFilteredList,
        filteredList,
        listCounters,
        setListCounters,
        signed,
        notesGlobal,
        setNoteGlobal,
        keyNote,
        setKeyNote,
        consistencia,
        setConsistencia,
        dateConsistencia,
        setDateConsistencia,
        dashboardData,
        setDashboardData,
        date,
        setDate,
        getDashboard,
        clients,
        setClients,
        getClients,
        tiponfce,
        setTipoNfce,
        xmlEvento,
        setXmlEvento,
        tipo,
        setTipo,
        numberNote,
        setNumberNote,
        serie,
        setSerie,
        xmls,
        setXmls,
        loadXmls,
        setLoadXmls,
        textLoadXmls,
        setTextLoadXmls,
        cnpj,
        setCnpj,
        situation,
        setSituation,
        keyAcess,
        setKeyAcess,
        DownloadsXml,
        cancelDownload,
        duration,
        setDuration,
        formatTime,
      }}
    >
      {children}
    </context.Provider>
  );
}

import React from 'react';
import ReportSintetic from './notes/reportSintetic';

// import { Container } from './styles';

function PrintNotes() {
  return (
    <div>
        <ReportSintetic/>
    </div>
  )
}

export default PrintNotes;
import Header from "../../components/header/Header";
import SearchBar from "../../components/Searchbar";
import { useState, useEffect, useContext, useRef } from "react";
import {
  Modal,
  Select,
  Dialog,
  DialogContent,
  Button,
  Autocomplete,
  TextField,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "react-datepicker";
import { Card, Checkbox } from "@mui/material";
import { SplitButton } from "primereact/splitbutton";
import TableNotes from "./../../components/tableNotes";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import api from "./../../services/api";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { context } from "../../contexts";
import { format } from "date-fns";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR"; // Importe a localização para português do Brasil
import ReportAnalytcal from "./ReportAnalitcal";
import ReportSintetic from "./reportSintetic";
import PrintComponent from "../../components/PrintComponent";
import NoteCancelled from "./noteCancelled";
import JSZip from "jszip";
import MonitoringDownloadXml from "../../components/MonitoringDownloadXml";

export default function Notes() {
  const [userRepair, setUserRepair] = useState(false);

  const [nfe, setNfe] = useState(false);
  const [nfce, setNfce] = useState(false);
  const [sat, setSat] = useState(false);
  const [all, setAll] = useState(true);

  const [disabled, setDisabled] = useState(true);
  const [search, setSearch] = useState("");
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState(null);
  const [notes, setNotes] = useState([]);
  const [openReport, setOpenReport] = useState(false);
  const [openSintetic, setOpenSintetic] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [openNoteCanceled, setOpenNoteCanceled] = useState(false);
  const [xmlnotaCancelada, setXmlNotaCancelada] = useState("");

  registerLocale("pt-BR", ptBR);
  setDefaultLocale("pt-BR");
  const {
    userData,
    notesGlobal,
    setNoteGlobal,
    consistencia,
    setConsistencia,
    dateConsistencia,
    setDateConsistencia,
    tipo,
    setTipo,
    numberNote,
    setNumberNote,
    serie,
    setSerie,
    xmls,
    setXmls,
    loadXmls,
    setLoadXmls,
    textLoadXmls,
    setTextLoadXmls,
    cnpj,
    setCnpj,
    situation,
    setSituation,
    keyAcess,
    setKeyAcess,
    DownloadsXml,
    date,
    setDate,
    cancelDownload,
  } = useContext(context);

  // quando o usuario clicar em consistencia ele abre o modal pedindo confirmação
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [loadDialog, setLoadDiaolg] = useState(false);

  //controla o split button
  const items = [
    {
      label: "Relação Sintética das Notas",
      command: () => setOpenSintetic(true),
    },

    /*
        {
            label: 'Relação Analítica das Notas',
            command: () => navigate('/reportAnalitycal')
        },
        {
            label: 'Resumo Fiscal',
            command: () => navigate('/resumeFiscal')
        },*/
  ];

  //dados ficticios
  const notasFiscais = [
    {
      mod: 55,
      serie: "001",
      numero: "123456",
      chaveAcesso: "35191234567890123456789012345678901234567890",
      cnpj: "12345678901234",
      cliente: "Empresa ABC",
      status: "Autorizado",
      data: "2024-05-31",
      hora: "12:30",
      total: 150.0,
    },
    {
      mod: 65,
      serie: "002",
      numero: "789012",
      chaveAcesso: "65191234567890123456789012345678901234567890",
      cnpj: "56789012345678",
      cliente: "Empresa XYZ",
      status: "Cancelado",
      data: "2024-05-30",
      hora: "15:45",
      total: 200.0,
    },
    {
      mod: 55,
      serie: "003",
      numero: "345678",
      chaveAcesso: "35191234567890123456789012345678901234567891",
      cnpj: "90123456789012",
      cliente: "Empresa 123",
      status: "Autorizado",
      data: "2024-05-29",
      hora: "10:15",
      total: 100.0,
    },
  ];

  useEffect(() => {
    setDateConsistencia(date);
  }, [date]);
  useEffect(() => {
    async function getDataUser() {
      const userDataString = localStorage.getItem("@user");

      const userdata = JSON.parse(userDataString);

      setUser(userdata);
      getClients(userdata);
    }

    getDataUser();
  }, []);

  async function getClients(userData) {
    setLoading(true);

    await axios
      .get(`${api}notafiscal/${userData?.tipo}/${userData?.usuario}`)
      .then((res) => {
        setClients(res.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Erro ao buscar clientes");
        setLoading(false);
      });
  }

  // formata a data e coloca no formato esperado pela api
  function FormatDate(value) {
    const formatt = format(value, "yy/MM");
    return formatt;
  }
  //AUTOORIZADAS SITUACAO 0  - CANCELADAS SITUACAO -C

  ///essa função busca a serie no momento que seleciono um cnpj
  const [serieList, setSerieList] = useState([]);
  async function getSerie() {
    if (tipo === "") {
      console.log(`${api}notafiscal/serie/${cnpj}/${FormatDate(date)}`);
      await axios
        .get(`${api}notafiscal/serie/${cnpj}/${FormatDate(date)}`)
        .then((res) => {
          setSerieList(res.data);
          if (res.data && res.data[0].SERIE) {
            setSerie(res.data[0].SERIE);
          }
        })
        .catch((error) => {});
    } else {
      await axios
        .get(`${api}notafiscal/serie/${cnpj}/${FormatDate(date)}?tipo=${tipo}`)
        .then((res) => {
          setSerieList(res.data);
          if (res.data && res.data[0].SERIE) {
            setSerie(res.data[0].SERIE);
          }
        })
        .catch((error) => {});
    }
  }
  useEffect(() => {
    if (cnpj !== "") {
      getSerie();
    }
  }, [cnpj, date]);
  // função para buscar as notas fiscais

  async function getNotes() {
    if (cnpj !== "") {
      setLoading(true);

      await axios
        .get(
          `${api}notafiscal/${cnpj}/${FormatDate(
            date
          )}?situacao=${situation}&tipo=${tipo}&numero=${numberNote}&serie=${serie}&chave=${keyAcess}`
        )
        .then((res) => {
          setLoading(false);
          setNotes(res.data);
          setNoteGlobal(res.data);
          setDisabled(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Erro ao buscar notas", error.message);
        });
    } else {
      toast.warn("É necessário selecionar um CNPJ");
    }
  }

  // função para buscar consistencia de notas
  async function getConsistencia() {
    if (notes.length > 0) {
      setLoadDiaolg(true);

      await axios
        .get(
          `${api}notafiscal/consistencia/${cnpj}/${FormatDate(
            date
          )}?tipo=${tipo}&serie=${serie}`
        )
        .then((res) => {
          setConsistencia(res.data);
          setOpen(false);
          //navigate('/reportAnalitycal')

          setLoadDiaolg(false);
          setOpenReport(true);
        })
        .catch((error) => {
          toast.error("Erro ao gerar relatório de consistencia");
          setLoadDiaolg(false);
        });
    } else {
      toast.error(
        "Não é possivel gerar o relatório de consistência quando não há notas"
      );
    }
  }

  const clientsList =
    clients &&
    clients.length > 0 &&
    clients.map((item) => ({
      label: `${item.NOME ? item.NOME : ""}  ${item.CNPJ}`,
      value: item.CNPJ,
    }));

  
  return (
    <div className="flex h-screen w-[100vw] overflow-hidden max-md:overflow-y-auto overflow-x-hidden">
      <Header />

      <div className="">
        <div className="mx-8 mt-4 max-md:mt-14 ">
          <p className="font-semibold">
            {" "}
            Notas Fiscais - Consultas e Operações
          </p>

          <div className="flex items-start gap-4 max-lg:flex-col max-lg:gap-0 ">
            <div className="my-2 gap-2 flex-col flex">
              <p className=" text-sm ">CNPJ/Chave de Acesso</p>
              <Autocomplete
                options={clients && clients.length > 0 ? clientsList : []}
                sx={{ width: 240 }}
                value={cnpj}
                onChange={(e, newValue) => {
                  setCnpj(newValue && newValue.value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Selecione um cliente" />
                )}
              />

              {/**  <Select
                                className="w-[15rem] h-[1.5rem]"
                                onChange={(e) => setCnpj(e.target.value)}
                            >
                                {
                                    clients && clients.length > 0 ?
                                        clients.map((item) => (
                                            <MenuItem style={{ fontSize: 12 }} value={item.CNPJ}

                                            >{item?.NOME} - {item?.CNPJ}</MenuItem>
                                        )) : <p>Buscando clientes....</p>
                                }
                            </Select> */}

              <div className="my-2">
                <SearchBar
                  placeholder={"Pesquisar por chave de acesso"}
                  value={keyAcess}
                  setSearch={setKeyAcess}
                />
              </div>
            </div>

            <div className="my-2">
              <div className="flex flex-col  gap-2">
                <p className=" text-sm ">Situação</p>
                <Select
                  className="w-[15rem] h-[1.5rem]"
                  onChange={(e) => setSituation(e.target.value)}
                  value={situation}
                  placeholder="Todas"
                >
                  <MenuItem value={"O"}>Autorizadas</MenuItem>
                  <MenuItem value={"C"}>Canceladas</MenuItem>
                  <MenuItem value={"T"}>Todas</MenuItem>
                </Select>

                <div className="flex gap-2 items-center">
                  <p className="font-semibold">Tipo</p>
                  <div className="flex gap-0 items-center">
                    <Checkbox
                      color="warning"
                      checked={nfe}
                      onClick={() => {
                        setNfe(true);
                        setNfce(false);
                        setAll(false);
                        setSat(false);
                        setTipo(55);
                      }}
                    />
                    <p className="text-sm">NFe</p>
                  </div>
                  <div className="flex gap-0 items-center">
                    <Checkbox
                      color="warning"
                      checked={nfce}
                      onClick={() => {
                        setNfe(false);
                        setNfce(true);
                        setAll(false);
                        setSat(false);
                        setTipo(65);
                      }}
                    />
                    <p className="text-sm">NFCe</p>
                  </div>
                  <div className="flex gap-0 items-center">
                    <Checkbox
                      color="warning"
                      checked={sat}
                      onClick={() => {
                        setNfe(false);
                        setNfce(false);
                        setAll(false);
                        setSat(true);
                        setTipo(59);
                      }}
                    />
                    <p className="text-sm">SAT</p>
                  </div>
                  <div className="flex gap-0 items-center">
                    <Checkbox
                      color="warning"
                      checked={all}
                      onClick={() => {
                        setNfe(false);
                        setNfce(false);
                        setAll(true);
                        setSat(false);
                        setTipo("");
                      }}
                    />
                    <p className="text-sm">Todos</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-8  flex flex-col gap-4">
              <input
                value={numberNote}
                onChange={(e) => setNumberNote(e.target.value)}
                type="number"
                placeholder={"N. Nota"}
                className="outline-none  w-[5rem] border-2 rounded-md text-sm"
              />
              <div className="gap-1 flex">
                <label className="text-sm">Serie</label>
                <Select
                  className="w-[5rem] h-[1.5rem]"
                  onChange={(e) => setSerie(e.target.value)}
                  value={serie}
                  placeholder="Série"
                >
                  {serieList.map((item) => (
                    <MenuItem value={item.SERIE}> {item.SERIE}</MenuItem>
                  ))}
                </Select>
              </div>
            </div>

            <div className="my-2">
              <p className=" text-sm ">Ano - Mês</p>
              <DatePicker
                selected={date}
                onChange={(date) => setDate(date)}
                dateFormat="MM/yyyy" // Define o formato para mostrar apenas o mês e o ano
                showMonthYearPicker // Mostra apenas o seletor de mês e ano
                className="border-2 outline-none border-gray-200 w-[6rem] rounded-md"
              />
            </div>

            <button
              className="mx-12 bg-orange-500 text-white px-4 rounded-md text-sm
                    hover:bg-orange-300 max-w-[15rem] my-8 min-h-7
                    "
              onClick={getNotes}
            >
              Pesquisar
            </button>
          </div>

          <div className="flex  mx-8 gap-4 ">
            <button
              className={` bg-red-500 text-white rounded-md text-sm
                    hover:bg-red-300 min-w-[6rem]  min-h-7 ${
                      disabled ? "bg-red-300" : ""
                    }`}
              disabled={disabled}
              onClick={() => setOpen(true)}
            >
              Consistência
            </button>
            <button
              className={` bg-green-500 text-white rounded-md text-sm
                    hover:bg-green-300 min-w-[6rem]  min-h-7  ${
                      disabled ? "bg-green-300" : ""
                    }`}
              onClick={DownloadsXml}
              disabled={disabled}
            >
              Baixar tudo
            </button>
            <SplitButton
              label="Relatórios"
              icon="pi pi-plus"
              model={items}
              className="bg-blue-500 text-white font-sans text-sm"
              menuStyle={{ fontSize: 14 }}
              disabled={disabled}
            />
          </div>
        </div>

        {loading ? (
          <div className="absolute left-[50%] top-[50%]">
            <CircularProgress color="warning" />
          </div>
        ) : (
          <div className="mx-8 my-2 overflow-hidden">
            {loading === false && notes.length === 0 ? (
              <p className="text-sm text-center">Nenhuma nota disponivel</p>
            ) : (
              <TableNotes
                data={notes}
                setOpenNote={setOpenNote}
                setOpenNoteCanceled={setOpenNoteCanceled}
                setXmlNotaCancelada={setXmlNotaCancelada}
              />
            )}
          </div>
        )}
      </div>

      <Dialog open={open}>
        <div className="h-16 px-6 gap-3 flex flex-col">
          <p>Confirma a geração do relatório? </p>
          <div className="flex items-center justify-around">
            <button
              className="text-sm border-2 px-3 rounded-md h-6 hover:text-red-500 duration-300"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </button>
            <button
              className="text-sm px-3 rounded-md  bg-orange-500 text-white h-6 hover:bg-orange-300 duration-300 w-20"
              onClick={getConsistencia}
            >
              {loadDialog ? (
                <CircularProgress color="inherit" size={15} />
              ) : (
                "Confirmar "
              )}
            </button>
          </div>
        </div>
      </Dialog>

      <Modal open={openReport} className="flex items-center justify-center">
        <div className="w-[85vw] h-[90vh] bg-white flex items-center justify-center">
          <ReportAnalytcal close={() => setOpenReport(false)} />
        </div>
      </Modal>
      <Modal open={openSintetic} className="flex items-center justify-center">
        <div className="w-[85vw] h-[90vh] bg-white flex items-center justify-center">
          <ReportSintetic close={() => setOpenSintetic(false)} />
        </div>
      </Modal>

      <Modal open={openNote} className="flex items-center justify-center">
        <div className="w-[85vw] h-[90vh] bg-white flex items-center justify-center">
          <PrintComponent close={() => setOpenNote(false)} />
        </div>
      </Modal>
      <Modal
        open={openNoteCanceled}
        className="flex items-center justify-center"
      >
        <div className="w-[85vw] h-[90vh] bg-white flex items-center justify-center">
          <NoteCancelled
            close={() => setOpenNoteCanceled(false)}
            xml={xmlnotaCancelada}
          />
        </div>
      </Modal>

      <MonitoringDownloadXml/>
      {/**   <Dialog open={loadXmls} >
                <DialogContent className="bottom-4">
                    <div className="flex flex-col items-center gap-2">
                        <p>{textLoadXmls}...</p>
                        <CircularProgress color='warning' size={20} />

                        <div>
                            <button className="bg-red-500 text-white px-2 rounded-sm text-xs hover:bg-red-600 duration-300 mt-4"
                                onClick={cancelDownload}
                            >Cancelar busca</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>*/}
    </div>
  );
}
